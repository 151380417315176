import global from './global';
import '../../vendor/jquery.cookie';

/**
 *  header [nologin]
 *  this is based from common (./modules/common), except:
 *    - there's no assumption of input fields apart from the header->search
 *    - only html checks present on the page based off of learn-more
 */

export default function () {
    $(function() {
        global();
    
        const $ieMessage = $('.ie-message');
    
        $ieMessage.on('click', '.j-ie-close', function () {
            let time = 525600 * 60000, // one year in miliseconds
                vdate = new Date();
    
            vdate.setTime(vdate.getTime() + time);
            document.cookie = 'fc_ie_closed=true; expires=' + vdate + '; path=/';
            $ieMessage.slideUp();
        });
    
        // don't show feedback links if IE9 or less
        const $html = $('html');
    
        if ($html.hasClass('ie9') || $html.hasClass('lt-ie9')) {
            $('.j-hide-if-oldIE').hide();
        }

        //cookie notifications, set cookie if we shown notification and user accept it
        if ($('#cookie-notifications-btn').length) {

            $('#cookie-notifications-btn').click(function () {
                let time = 525600 * 60000, // one year in miliseconds
                    vdate = new Date();

                vdate.setTime(vdate.getTime() + time);

                document.cookie = 'fc_cookie_notification_accepted=true; expires=' + vdate + '; path=/';

                $('.cookie-notifications').hide();
                $('body').removeClass('euro-message-active');
            });
        }
        
        if (typeof $.cookie !== 'undefined' && typeof window.FC !== 'undefined' && window.FC.site === 'findchips') { 
            if ($.cookie('fc_timezone') != Intl.DateTimeFormat().resolvedOptions().timeZone) {
                $.cookie('fc_timezone', Intl.DateTimeFormat().resolvedOptions().timeZone, {path: '/'});
            }
            if ($.cookie('fc_locale') != navigator.language) {
                $.cookie('fc_locale', navigator.language, {path: '/'});
            }
        }
    
        $('#search, #search-insight, #search-part, #parametric').submit(function (e) {
            const $this = $(this);
            let val = $this.find('input[type=text]').val(),
                $error = $this.find('.search-input-error');
    
            if (validTerm(val)) {
                let action = $this.prop('action');
                if (action.includes('/detail')) {
                    e.preventDefault();
                    location.href = action + '/' + encodeURIComponent(val.toLowerCase());
                }
                $error.hide();
                return true;
            } else {
                $error.show();
                $('.tt-menu, .autocomplete-holder').hide();
                return false;
            }
        });
    
        // remove error message on typing
        $('#search, #search-insight, #search-part, #parametric').keyup(function (e) {
            const $this = $(this);
            $this.find('.search-input-error').hide();
            if (e.keyCode === 13) {
                $this.submit();
            }
        });
    
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/fc-sw.js?v=' + __VERSION__ + '&e=' + __ENV__);
        }
    });
}

/**
 * cherry-picked from utils: ../lib/utils
 * @param  {[type]} val [description]
 * @return {[type]}     [description]
 */
function validTerm(val) {
    if (typeof val === 'string' && val) {

        val = val.replace(/^\s+|\s+$/g, '');
        if (val.length <= 2) {
            return false;
        }

        let regex = /([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+/;
        return !regex.test(val);
    }

    return false;
}
